import axios from 'axios'
// import { setLoading } from '../store/actions'
import { setLoading } from '../store/slices/questionnaireSlice'
import { APP_URL } from './constants'

import {
  getAnswer,
  getVerwarmingTableData,
  getAdviceKey,
  getAdviceKeyXL,
  // getOppervlakteKey
} from './helpers'
import { getArea } from './resultHelpers'

function getGasSelection(page7Answer) {
  switch (page7Answer) {
    case 0:
      return 1
    case 1:
      return 2
    case 2:
      return 3
    case 3:
      return 4

    default:
      return 1
  }
}

function getElecSelection(page7Answer) {
  switch (page7Answer) {
    case 0:
      return 5
    case 1:
      return 6
    case 2:
      return 7
    case 3:
      return 8
    case 4:
      return 9
    case 5:
      return 10

    default:
      return 1
  }
}

function getStookolieSelection(page7Answer) {
  switch (page7Answer) {
    case 0:
      return 11
    case 1:
      return 12
    case 2:
      return 13
    case 3:
      return 14

    default:
      return 1
  }
}

function getAndereelection(page7Answer) {
  switch (page7Answer) {
    case 0:
      return 15
    case 1:
      return 16
    case 2:
      return 17

    default:
      return 0
  }
}

export function getCalculationOfPage6(pages, isImprovement) {
  const page6Answer = getAnswer(pages, 6)
  const page7Answer = getAnswer(pages, 7)

  if (isImprovement) {
    return page7Answer + 1
  }

  if (page6Answer === 0) {
    return getGasSelection(page7Answer)
  }

  if (page6Answer === 1) {
    return getElecSelection(page7Answer)
  }

  if (page6Answer === 2) {
    return getStookolieSelection(page7Answer)
  }

  if (page6Answer === 3) {
    return getAndereelection(page7Answer)
  }

  return 1
}

export function getCalculationOfPage7(pages) {
  const value = getAnswer(pages, 8) + 1
  return value
}
export async function getCalculationOfPage3(answer, answer1, input) {
  const response = await axios.get(`${APP_URL}/dimensions`)
  const resAnswer = getArea(answer, answer1, input, response.data[0])
  const arr = {
    groot: 1,
    gemiddeld: 2,
    klein: 3,
  }
  const result = arr[resAnswer]
  return result
}

export async function getCalculationPayload(
  pages,
  isImprovement,
  solarArea,
  isVerwarming,
) {
  if(pages){
    const page3Calculation = await getCalculationOfPage3(
      pages[3]?.answerNo,
      pages[1]?.answerNo,
      pages[3]?.oppervlakte_input,
    )
    return {
      1: pages[1].answerNo + 1,
      2: pages[2].answerNo + 1,
      3: page3Calculation,
      5: pages[5].answerNo + 1,
      6: getCalculationOfPage6(pages, isVerwarming),
      7: getCalculationOfPage7(pages) || 1,
      8: pages[9].answerNo + 1,
      9: pages[10].answerNo + 1,
      10: pages[11].answerNo + 1,
      11: pages[12].answerNo + 1,
      12: pages[13].answerNo + 1,
      13: pages[14]?.answerNo + 1,
      14: pages[15].answerNo + 1,
      15: pages[16].answerNo + 1,
      16: pages[17].answerNo + 1,
      17: pages[18].answerNo + 1,
      18: pages[19] && pages[19].answerNo ? pages[19].answerNo + 1 : 1,
      19: pages[20].answerNo + 1,
      20: pages[21].answerNo + 1,
      // Desactivate pannel from jelle calculation
      // 21:
      //   pages[23] && pages[23].aantal_zonnepanelen
      //     ? pages[23].aantal_zonnepanelen
      //     : 0,
      21: 0,
      e23: pages[26].elektriciteitsverbruik || 0,
      g23: pages[26].aardgasverbruik || 0,
      p23: pages[26].zonnepanelen || 0,
    }
  }else{
    return null;
  }



}

export function parseEnergy(response, type) {
  // Change for addition of parsedEnergy
  if (type === 0) {
    return response?.estimatedSavings?.gas
  }
  return response?.estimatedSavings?.elec
}

export function parseRate(response, type) {
  // change for return one or another one
  if (type === 0) {
    return response?.data.data[0][2].value
  }
  if (type === 1) {
    return response?.data.data[0][0].value
  }
  return response?.data.data[0][1].value

  // if (type === 'hybrid') {
  //   switch (page6Answer) {
  //     case 0:
  //     case 1:
  //     default:
  //       return response.data.data[0][0].value
  //   }
  // }
  // return response.data.data[0][0].value
}

async function getSolarArea(itemKey, area) {
  if (itemKey === 'zonnepanelen') {
    // const response = await axios.get(`${APP_URL}/parameters`)
    const parameters = JSON.parse(localStorage.getItem('parameters'))
    const zonnepanelenSquareMeter =
      parameters?.data?.data[0].find((item) => item.id === '30') || 0
    const unit = zonnepanelenSquareMeter.value
    return Math.floor(area / unit)
  }
  return 0
}

// Result page calculations helper
export async function getResultCalculations(
  pages,
  improvedPagesAll,
  improvedPages,
  setState,
  type,
  itemKey,
  area,
  userId,
) {
  setState((st) => ({
    ...st,
    loading: true,
  }))
  const solarArea = await getSolarArea(itemKey, area)
  const pagesPayload = await getCalculationPayload(pages)
  const improvedPagesPayload = await getCalculationPayload(
    improvedPages,
    true,
    solarArea,
    ['gas', 'elektriciteit', 'stookolie', 'ean_andere_bron'].includes(itemKey),
  )
  const improvedPagesPayloadAll = await getCalculationPayload(
    improvedPagesAll,
    true,
    solarArea,
    ['gas', 'elektriciteit', 'stookolie', 'ean_andere_bron'].includes(itemKey),
    true,
  )
  const storedCurrentPayload = localStorage.getItem('currentPayload')
  const storeImprovedPayload = localStorage.getItem('improvedPayload')
  const storedResult = localStorage.getItem('result')
  const checkCurrentPayload =
    JSON.stringify(pagesPayload) === storedCurrentPayload
  const checkImprovedPayload =
    JSON.stringify(improvedPagesPayload) === storeImprovedPayload
  const parameters = JSON.parse(localStorage.getItem('parameters'))
  const unitRateGas = parseRate(parameters, 0)
  const unitRateElec = parseRate(parameters, 1)
  const unitRateInjectie = parseRate(parameters, 2)

  const page25 = pages?.[25] && pages?.[25].answerNo
  let verkoopElec = 0
  const verbruikZonnepaneelKwh =
    parameters.data?.data[0].find((item) => item.id === '35') || 0
  const zelfconsumptieZonnepanelen =
    parameters.data?.data[0].find((item) => item.id === '33') || 0

  await setState((st) => ({
    ...st,
    calculations: {
      ...st.calculations,
      energyDifference: 0,
      energyRate: 0,
    },
    loading: false,
  }))

  // if (checkCurrentPayload && checkImprovedPayload && storedResult) {
  //   const result = JSON.parse(storedResult)
  //   const pagesEnergyGas = Number(parseEnergy(result.data, 0))
  //   const pagesEnergyElec = Number(parseEnergy(result.data, 1))
  //   const energyDifferenceBoth = Number(
  //     pagesEnergyGas + pagesEnergyElec,
  //   ).toFixed(2)
  //   let energyDifference = energyDifferenceBoth
  //   let energyRate = Number(
  //     Number(pagesEnergyGas * unitRateGas) +
  //       Number(pagesEnergyElec * unitRateElec),
  //   ).toFixed(2)

  //   // if (type === 'solar' && page25 == 0) {
  //   if (type === 'solar') {
  //     energyDifference =
  //       (area *
  //         verbruikZonnepaneelKwh.value *
  //         zelfconsumptieZonnepanelen.value) /
  //       100
  //     energyRate =
  //       (area *
  //         verbruikZonnepaneelKwh.value *
  //         zelfconsumptieZonnepanelen.value *
  //         unitRateElec +
  //         area *
  //           verbruikZonnepaneelKwh.value *
  //           (100 - zelfconsumptieZonnepanelen.value) *
  //           unitRateInjectie) /
  //       100
  //     verkoopElec =
  //       area *
  //       verbruikZonnepaneelKwh.value *
  //       ((100 - zelfconsumptieZonnepanelen.value) / 100)
  //   }
  //   // if (type === 'solar' && page25 == 1) {
  //   //   energyDifference = area * verbruikZonnepaneelKwh.value
  //   //   energyRate = area * verbruikZonnepaneelKwh.value * unitRateElec
  //   //   verkoopElec =
  //   //     area *
  //   //     verbruikZonnepaneelKwh.value *
  //   //     (100 - zelfconsumptieZonnepanelen.value)
  //   // }
  //   // console.log("getResultCalculations area",area)
  //   // console.log("getResultCalculations verbruikZonnepaneelKwh.value",verbruikZonnepaneelKwh.value)
  //   // console.log("getResultCalculations zelfconsumptieZonnepanelen.value",zelfconsumptieZonnepanelen.value)
  //   // console.log("getResultCalculations unitRateElec",unitRateElec)
  //   // console.log("getResultCalculations energyRate",energyRate)

  //   await setState((st) => ({
  //     ...st,
  //     calculations: {
  //       energyDifference,
  //       energyRate,
  //       pagesEnergyGas,
  //       pagesEnergyElec,
  //       verkoopElec,
  //     },
  //     loading: false,
  //   }))
  //   // console.log('\r\n------------ Modal Calculation - Local ------------\r\n')
  //   // console.log('unitRateGas', unitRateGas)
  //   // console.log('unitRateElec', unitRateElec)
  //   // console.log('unitRateInjectie', unitRateInjectie)
  //   // console.log('energyDifferenceBoth', energyDifferenceBoth)
  //   // console.log('pagesEnergyGas', pagesEnergyGas)
  //   // console.log('pagesEnergyElec', pagesEnergyElec)
  //   // console.log('energyDifference', energyDifference)
  //   // console.log('energyRate', energyRate)
  //   // console.log('-------------------------------------------\r\n')
  // } else {
  //   setState((st) => ({
  //     ...st,
  //     loading: true,
  //   }))
  //   localStorage.setItem('currentPayload', JSON.stringify(pagesPayload))
  //   localStorage.setItem(
  //     'improvedPayload',
  //     JSON.stringify(improvedPagesPayload),
  //   )

  //   axios
  //     .post(`${APP_URL}/calculation?getResultCalculations`, {
  //       current: pagesPayload,
  //       improvements: improvedPagesPayload,
  //       userId,
  //     })
  //     .then(async (response) => {
  //       localStorage.setItem('result', JSON.stringify(response))
  //       const pagesEnergyGas = Number(parseEnergy(response.data, 0))
  //       const pagesEnergyElec = Number(parseEnergy(response.data, 1))
  //       const energyDifferenceBoth = Number(
  //         pagesEnergyGas + pagesEnergyElec,
  //       ).toFixed(2)
  //       let energyDifference = energyDifferenceBoth
  //       let energyRate = Number(
  //         Number(pagesEnergyGas * unitRateGas) +
  //           Number(pagesEnergyElec * unitRateElec),
  //       ).toFixed(2)

  //       // if (type === 'solar' && page25 == 0) {
  //       if (type === 'solar') {
  //         energyDifference =
  //           (area *
  //             verbruikZonnepaneelKwh.value *
  //             zelfconsumptieZonnepanelen.value) /
  //           100
  //         energyRate =
  //           (area *
  //             verbruikZonnepaneelKwh.value *
  //             zelfconsumptieZonnepanelen.value *
  //             unitRateElec +
  //             area *
  //               verbruikZonnepaneelKwh.value *
  //               (100 - zelfconsumptieZonnepanelen.value) *
  //               unitRateInjectie) /
  //           100
  //         verkoopElec =
  //           area *
  //           verbruikZonnepaneelKwh.value *
  //           ((100 - zelfconsumptieZonnepanelen.value) / 100)
  //       }
  //       // if (type === 'solar' && page25 == 1) {
  //       //   energyDifference = area * verbruikZonnepaneelKwh.value
  //       //   energyRate = area * verbruikZonnepaneelKwh.value * unitRateElec
  //       //   verkoopElec =
  //       //     area *
  //       //     verbruikZonnepaneelKwh.value *
  //       //     (100 - zelfconsumptieZonnepanelen.value)
  //       // }

  //       // console.log("getResultCalculations area",area)
  //       // console.log("getResultCalculations verbruikZonnepaneelKwh.value",verbruikZonnepaneelKwh.value)
  //       // console.log("getResultCalculations unitRateElec",unitRateElec)
  //       await setState((st) => ({
  //         ...st,
  //         calculations: {
  //           energyDifference,
  //           energyRate,
  //           pagesEnergyGas,
  //           pagesEnergyElec,
  //           verkoopElec,
  //         },
  //         loading: false,
  //       }))
  //       // console.log('\r\n------------ Modal Calculation - API ------------\r\n')
  //       // console.log('unitRateGas', unitRateGas)
  //       // console.log('unitRateElec', unitRateElec)
  //       // console.log('unitRateInjectie', unitRateInjectie)
  //       // console.log('energyDifferenceBoth', energyDifferenceBoth)
  //       // console.log('pagesEnergyGas', pagesEnergyGas)
  //       // console.log('pagesEnergyElec', pagesEnergyElec)
  //       // console.log('energyDifference', energyDifference)
  //       // console.log('energyRate', energyRate)
  //       // console.log('-------------------------------------------\r\n')
  //     })
  //     .catch((exception) => {
  //       console.log(exception)
  //       setState((st) => ({
  //         ...st,
  //         loading: false,
  //       }))
  //     })
  // }
  // always fetch from api case
  
    setState((st) => ({
      ...st,
      loading: true,
    }))
    localStorage.setItem('currentPayload', JSON.stringify(pagesPayload))
    localStorage.setItem(
      'improvedPayload',
      JSON.stringify(improvedPagesPayload),
    )

    axios
      .post(`${APP_URL}/calculation?getResultCalculations`, {
        current: pagesPayload,
        improvements: improvedPagesPayload,
        userId,
      })
      .then(async (response) => {
        localStorage.setItem('result', JSON.stringify(response))
        const pagesEnergyGas = Number(parseEnergy(response.data, 0))
        const pagesEnergyElec = Number(parseEnergy(response.data, 1))
        const energyDifferenceBoth = Number(
          pagesEnergyGas + pagesEnergyElec,
        ).toFixed(2)
        let energyDifference = energyDifferenceBoth
        let energyRate = Number(
          Number(pagesEnergyGas * unitRateGas) +
            Number(pagesEnergyElec * unitRateElec),
        ).toFixed(2)

        // if (type === 'solar' && page25 == 0) {
        if (type === 'solar') {
          energyDifference =
            (area *
              verbruikZonnepaneelKwh.value *
              zelfconsumptieZonnepanelen.value) /
            100
          energyRate =
            (area *
              verbruikZonnepaneelKwh.value *
              zelfconsumptieZonnepanelen.value *
              unitRateElec +
              area *
                verbruikZonnepaneelKwh.value *
                (100 - zelfconsumptieZonnepanelen.value) *
                unitRateInjectie) /
            100
          verkoopElec =
            area *
            verbruikZonnepaneelKwh.value *
            ((100 - zelfconsumptieZonnepanelen.value) / 100)
        }
        // if (type === 'solar' && page25 == 1) {
        //   energyDifference = area * verbruikZonnepaneelKwh.value
        //   energyRate = area * verbruikZonnepaneelKwh.value * unitRateElec
        //   verkoopElec =
        //     area *
        //     verbruikZonnepaneelKwh.value *
        //     (100 - zelfconsumptieZonnepanelen.value)
        // }

        // console.log("getResultCalculations area",area)
        // console.log("getResultCalculations verbruikZonnepaneelKwh.value",verbruikZonnepaneelKwh.value)
        // console.log("getResultCalculations unitRateElec",unitRateElec)
        await setState((st) => ({
          ...st,
          calculations: {
            energyDifference,
            energyRate,
            pagesEnergyGas,
            pagesEnergyElec,
            verkoopElec,
          },
          loading: false,
        }))
        // console.log('\r\n------------ Modal Calculation - API ------------\r\n')
        // console.log('unitRateGas', unitRateGas)
        // console.log('unitRateElec', unitRateElec)
        // console.log('unitRateInjectie', unitRateInjectie)
        // console.log('energyDifferenceBoth', energyDifferenceBoth)
        // console.log('pagesEnergyGas', pagesEnergyGas)
        // console.log('pagesEnergyElec', pagesEnergyElec)
        // console.log('energyDifference', energyDifference)
        // console.log('energyRate', energyRate)
        // console.log('-------------------------------------------\r\n')
      })
      .catch((exception) => {
        console.log(exception)
        setState((st) => ({
          ...st,
          loading: false,
        }))
      })
  
}
// export async function getDimensionsZonnepannelen(
//   localImprovements
// ) {

//   // console.log("getDimensionsZonnepannelen")
//   const oppervlakteId = 1
//   await axios
//     .get(`${APP_URL}/dimensions?getDimensionsZonnepannelen`)
//     .then((response) => {
//       const data = response.data.filter((d) => Number(d.id) === oppervlakteId)
//       const buildingData=response.data

//       const opperKey = getOppervlakteKey(localImprovements, buildingData && buildingData[0])

//       const area = Math.round(data[0][opperKey]);
//       // console.log("getDimensionsZonnepannelen data", data)
//       // console.log("getDimensionsZonnepannelen buildingData", buildingData)
//       // console.log("getDimensionsZonnepannelen opperKey", opperKey)
//       console.log("getDimensionsZonnepannelen", area)
//       return area
//     })
//     .catch((err) => {
//       console.log("error getDimensionsZonnepannelen",err)
//         return 0
//     })
// }
export function getDimensions(
  areaSet,
  opperKey,
  oppervlakteId,
  modalTitle,
  page13,
  setState,
  resultSetState,
  pageIndex
) {
  setState((st) => ({
    ...st,
    loading: true,
  }))

  axios
    .get(`${APP_URL}/dimensions`)
    .then((response) => {
      const data = response.data.filter((d) => Number(d.id) === oppervlakteId)
      const zonneweringOne = response.data.filter(
        (d) => Number(d.id) === oppervlakteId.key1,
      )
      const zonneweringTwo = response.data.filter(
        (d) => Number(d.id) === oppervlakteId.key2,
      )
      const groendakOne = response.data.filter(
        (d) => Number(d.id) === oppervlakteId.key1,
      )
      const groendakTwo = response.data.filter(
        (d) => Number(d.id) === oppervlakteId.key2,
      )
      const hoofdak = response.data.filter(
        (d) => Number(d.id) === oppervlakteId.key3,
      )
        // console.log("===>",pageIndex)
        // regenput
        if(pageIndex == 29){
          const hoofdgebouw = (response.data.filter((d) => Number(d.id) === oppervlakteId.key1))[0][opperKey]
          const bijgebouw = response.data.filter((d) => Number(d.id) === oppervlakteId.key2)[0][opperKey]
          const total = hoofdgebouw + bijgebouw;
          areaSet(Math.round(total))
          setState((st) => ({
            ...st,
            loading: false,
            areaApiValue: Math.round(total),
          }))
        }else{
          if (
            modalTitle !== 'Zonnepanelen' &&
            modalTitle !== 'Zonnewering' &&
            modalTitle !== 'Groendak' &&
            modalTitle !== 'Hoofddak'
          ) {
            // const localArea = localStorage.getItem('area')
            // if (localArea != Math.round(data[0][opperKey])) {
            // localStorage.setItem('area', Math.round(data[0][opperKey]))
            areaSet(Math.round(data[0][opperKey]))
            setState((st) => ({
              ...st,
              loading: false,
              areaApiValue: Math.round(data[0][opperKey]),
            }))
            // }
          }
          if (modalTitle === 'Zonnewering') {
            const data =
              Math.round(zonneweringOne[0][opperKey]) +
              Math.round(zonneweringTwo[0][opperKey])
            areaSet(data)
            setState((st) => ({
              ...st,
              loading: false,
              areaApiValue: data,
            }))
          }
          if (modalTitle === 'Groendak') {
            const data =
              Math.round(groendakOne[0][opperKey]) +
              Math.round(groendakTwo[0][opperKey])
            areaSet(data)
            setState((st) => ({
              ...st,
              loading: false,
              areaApiValue: data,
            }))
          }
          if (modalTitle === 'Hoofddak') {
            if (page13 === 4) {
              const data =
                Math.round(groendakOne[0][opperKey]) +
                Math.round(groendakTwo[0][opperKey])
              areaSet(data)
              setState((st) => ({
                ...st,
                loading: false,
                areaApiValue: data,
              }))
            } else {
              areaSet(Math.round(hoofdak[0][opperKey]))
              setState((st) => ({
                ...st,
                loading: false,
                areaApiValue: Math.round(hoofdak[0][opperKey]),
              }))
            }
          }
        }


      resultSetState((st) => ({
        ...st,
        isAreaUpdated: {
          ...st.isAreaUpdated,
          [modalTitle]: true,
        },
      }))
    })
    .catch((err) => {
      console.log(err)
      setState((st) => ({
        ...st,
        loading: false,
      }))
    })
}

export function getImprovements(setState, modalTitle, selectedOptText, area,pageIndex,selectedOption,vergronenArea,localImprovements) {
  setState((st) => ({
    ...st,
    loading: true,
  }))
  if(pageIndex == 30){
    modalTitle = "Ontharden";
  }
  
  axios
    .get(`${APP_URL}/improvements`)
    .then((response) => {
      let tableData = []
      let improvementPeram = ''

      tableData = response.data.filter(
        (table) => table.improvementName === modalTitle,
      )
      if(pageIndex == 30){
      // Vergroenen
      let investmentCost = tableData[0].improvementOptions[0].investmentCost

      setState((st) => ({
        ...st,
        investCost: vergronenArea.reduce((partialSum, a) => partialSum + a, 0) * investmentCost,
        loading: false,
        }))
      }
      else if(pageIndex == 29){
        //Regentput
        if(localImprovements[1].answerNo === 0 || localImprovements[1].answerNo === 1){
          tableData = tableData[0].improvementOptions.filter(
            (prem) => prem.selectedOption === "Regenput voor (half)open bebouwing",
          )
          setState((st) => ({
            ...st,
            investCost: tableData[0].investmentCost,
            loading: false,
          }))
        }else{
          tableData = tableData[0].improvementOptions.filter(
            (prem) => prem.selectedOption === "Regenput voor gesloten bebouwing",
          )
          setState((st) => ({
            ...st,
            investCost: tableData[0].investmentCost,
            loading: false,
          }))
        }


      }else{


        if (
          modalTitle === 'Verwarming: Gas' ||
          modalTitle === 'Verwarming: Elektriciteit' ||
          modalTitle === 'Verwarming: Stookolie' ||
          modalTitle === 'Verwarming: Anderebron'
        ) {
          tableData = getVerwarmingTableData(
            response.data,
            modalTitle,
            selectedOptText,
          )
        } else {
  
          tableData = response.data.filter(
            (table) => table.improvementName === modalTitle,
          )
          if (modalTitle === 'Zonnepanelen') {
            const investCost =
              tableData?.[0]?.improvementOptions?.[0]?.investmentCost
  
            setState((st) => ({
              ...st,
              investCost: investCost * area,
              loading: false,
            }))
          }
        }  
        if (selectedOptText !== undefined) {
          if (tableData.length > 0) {
            improvementPeram = tableData[0].improvementPeram
            tableData = tableData[0].improvementOptions.filter(
              (prem) => prem.selectedOption === selectedOptText,
            )
          }
          if (tableData.length > 0) {
            if (improvementPeram === '€/m²') {
              setState((st) => ({
                ...st,
                investCost: tableData[0].investmentCost * area,
                loading: false,
              }))
            } else {
              setState((st) => ({
                ...st,
                investCost: tableData[0].investmentCost,
                loading: false,
              }))
            }
          } else {
            setState((st) => ({
              ...st,
              investCost: 0,
              loading: false,
            }))
          }
        }



      }
      
    })
    .catch((err) => {
      console.log(err)
      setState((st) => ({
        ...st,
        loading: false,
      }))
    })
}

export function getGroups(
  setState,
  premImprovment,
  oppervlakte,
  investCost,
  page4,
  pageIndex,
  vergronenArea
) {
  setState((st) => ({
    ...st,
    loading: true,
  }))
  axios
    .post(`${APP_URL}/groups/improvements/`, { data: premImprovment })
    .then(async (response) => {


      const { data } = response
      const premiumsTotal = data

      const paramsResponse = JSON.parse(localStorage.getItem('parameters'))

      const parameters = paramsResponse?.data?.data?.[0] || []

      const solarParam = parameters.find((param) => param?.id === '34')?.value || 0
      const getPercentageValue = (isPercent, value, totalValue, xValue) => {
        if (isPercent) {
          const percentValue = (value / 100) * totalValue
          if (percentValue < xValue) {
            return percentValue
          }

          return xValue
        }

        return value
      }
      const premiesData = []

      await premiumsTotal.map((item) => {
        const filteredItem = item
        let calculateFormula

        if (item.formula === 'X * oppervlakte') {
          calculateFormula = Number(item.x * oppervlakte)
          if (calculateFormula > Number(item.maximum)) {
            premiesData.push({
              value: item.maximum,
              percentage: item.percentage,
              xValue: item.x,
              formula: item.formula,
              improvement: item.improvement,
            })
          }
        } else if (item.formula === 'X%') {
          calculateFormula = Number((item.x / 100) * investCost)
          if (calculateFormula > Number(item.maximum)) {
            premiesData.push({
              value: item.maximum,
              percentage: item.percentage,
              xValue: item.x,
              formula: item.formula,
              improvement: item.improvement,
            })
          }
        } else if (item.formula === 'X * kWp') {
          calculateFormula = oppervlakte * Number(item.x) * solarParam
          if (calculateFormula > Number(item.maximum)) {
            premiesData.push({
              value: item.maximum,
              percentage: item.percentage,
              xValue: item.x,
              formula: item.formula,
              improvement: item.improvement,
            })
          }
        } else {
          premiesData.push({
            value: item.maximum,
            percentage: item.percentage,
            xValue: item.x,
            formula: item.formula,
            improvement: item.improvement,
          })
        }
      })

      let summ = []

      if(pageIndex == 30){
        let premieVoortuin = parameters.filter((data) => data.id == 47)[0].value * vergronenArea[0] ,
        premieAchtertuin = parameters.filter((data) => data.id == 48)[0].value * vergronenArea[1],
        premieOprit = parameters.filter((data) => data.id == 49)[0].value * vergronenArea[2];
        let totalArea =  vergronenArea[0] +  vergronenArea[1] +  vergronenArea[2]
        setState((st) => ({ ...st, premAmount: totalArea >= 10 ? Number(premieVoortuin+premieAchtertuin+premieOprit) : 0, loading: false }))

      }else if (data.length) {
        await Promise.all(


          data.map(async (t) => {
            const max = t.percentage
              ? (investCost / 100) * t.maximum
              : t.maximum
              ? t.maximum
              : 1000000000000
            if (t.formula === 'X') {
              if (max && t.x > max) {
                summ.push({
                  value: Number(max),
                  type: t.type,
                  groupsId: t.groupsId,
                })
              } else {
                summ.push({ value: t.x, type: t.type, groupsId: t.groupsId })
              }
            }
            if (t.formula === 'X * oppervlakte') {
              const calculateFormula = Number(t.x * oppervlakte)

              if (max && calculateFormula > max) {
                summ.push({
                  value: Number(max),
                  type: t.type,
                  groupsId: t.groupsId,
                })
              } else {
                summ.push({
                  value: calculateFormula,
                  type: t.type,
                  groupsId: t.groupsId,
                })
              }
            }
            if (t.formula === 'X%') {
              const calculateFormula = Number((t.x / 100) * investCost)

              if (max && calculateFormula > max) {
                summ.push({
                  value: Number(max),
                  type: t.type,
                  groupsId: t.groupsId,
                })
              } else {
                summ.push({
                  value: calculateFormula,
                  type: t.type,
                  groupsId: t.groupsId,
                })
              }
            }
            if (t.formula === 'X * kWp') {
              const calculateFormula = oppervlakte * Number(t.x) * solarParam

              if (max && calculateFormula > max) {
                summ.push({
                  value: Number(max),
                  type: t.type,
                  groupsId: t.groupsId,
                })
              } else {
                summ.push({
                  value: calculateFormula,
                  type: t.type,
                  groupsId: t.groupsId,
                })
              }
            }
          }),
        )

        if (page4 === 0) {
          summ = summ.filter((a) => {
            return a.type === 'Bewoner'
          }, 0)
        } else {
          summ = summ.filter((a) => {
            return a.type === 'Verhuurder'
          }, 0)
        }

        const p = []
        await summ.map((d, index) => {
          if (index > 0) {
            if (Number(p[index - 1].groupsId) === Number(d.groupsId)) {
              if (p[index - 1].value > d.value) {
                p.pop()
                p.push(d)
              }
            } else {
              p.push(d)
            }
          } else {
            p.push(d)
          }
        })

        summ = p.reduce((a, b) => {
          return a + b.value
        }, 0)
        setState((st) => ({ ...st, premAmount: summ, loading: false }))
      } else {
        setState((st) => ({ ...st, premAmount: 0, loading: false }))
      }

    })
    .catch((err) => {
      console.log(err)
      setState((st) => ({
        ...st,
        loading: false,
      }))
    })
}

export function getAdviceText(
  setState,
  status,
  title,
  pageIndex,
  answerNo,
  selectedOption,
) {
  setState((st) => ({
    ...st,
    loading: true,
  }))

  if(pageIndex == 30){title='Vergroenen'}
  
  const adviceKey = getAdviceKey(status, title, answerNo, selectedOption)
  const adviceKeyXL = getAdviceKeyXL(status, title, answerNo, selectedOption)
  axios
    // .get(`${APP_URL}/page/9`)
    // .get(`${APP_URL}/page/9`)
    .get(`${APP_URL}/page/67554a0a-0884-11ef-af54-024264400007`)
    .then((response) => {
      let data = response.data[0].pageContent
      const dataXL = data.filter((d) => d.key === adviceKeyXL)
      data = data.filter((d) => d.key === adviceKey)
      if (data.length) {
        console.log(`Advise key (${adviceKey}): `, data[0].value)
        setState((st) => ({
          ...st,
          adviceContent: data[0].value,
          loading: false,
        }))
      }
      if (dataXL.length) {
        console.log(`Advise key (${adviceKeyXL}): `, dataXL[0].value)
        setState((st) => ({
          ...st,
          adviceContentXL: dataXL[0].value,
          loading: false,
        }))
      }
    })
    .catch((err) => {
      console.log(err)
      setState((st) => ({
        ...st,
        loading: false,
      }))
    })
}

export async function sessionPutCall(userId, data) {
  // await axios.put(`${APP_URL}/session/${userId}`, data)
  axios.put(`${APP_URL}/session/${userId}`, data)
}

export async function answerPostCall(userId, questionText, answer) {
  // await axios.post(`${APP_URL}/session/answer`, {
  //   question_text: questionText,
  //   selected_answer: answer,
  //   sessionId: userId,
  // })
  // await sessionPutCall(userId, {})

  axios.post(`${APP_URL}/session/answer`, {
    question_text: questionText,
    selected_answer: answer,
    sessionId: userId,
  })
  await sessionPutCall(userId, {})
}

export function improvementPostCall(userId, questionText, answer) {
  axios.post(`${APP_URL}/session/improvement`, {
    improvement_text: questionText,
    selected_improvement: answer,
    sessionId: userId,
  })
  sessionPutCall(userId, {})
}

function solarLogic(pages, solarParameter = 0, energyUseZonnePannelen = 0) {
  if (pages?.[22]?.answerNo === 0) {
    const page23Input = pages?.[23]?.aantal_zonnepanelen || 0
    return page23Input * solarParameter?.value
  }

  return Math.round(energyUseZonnePannelen)
}

export async function getPage26Responsese(pages, userId, dispatch) {
  try {
    dispatch(setLoading(true))
    const pagesPayload = await getCalculationPayload(pages)
    pagesPayload.e23 = 0
    pagesPayload.g23 = 0
    const responses = await Promise.all([
      axios.post(`${APP_URL}/calculation?getPage26Responsese`, {
        current: pagesPayload,
        improvements: pagesPayload,
        userId,
      }),
      axios.get(`${APP_URL}/parameters`),
    ])
    const { energyUseElec, energyUseGas, energyUseZonnePannelen } =
      responses?.[0]?.data?.current || {}
    const parameters = responses?.[1]?.data?.data?.[0] || []

    localStorage.setItem('parameters', JSON.stringify(responses?.[1]))
    localStorage.setItem('energyGasNew_original', energyUseGas + energyUseElec)
    localStorage.setItem('energyGasNew', energyUseGas + energyUseElec)
    const firstTime = localStorage.getItem('firstTime26')
    if (Number(firstTime) !== 1) {
      localStorage.setItem('firstTime26', 1)
    }
    // const solarParameter = parameters.find((param) => param.name === 'Opbrengst per zonnepaneel')
    const energieproductie = parameters.find(
      (param) => param.name === 'Zelfconsumptie zonnepanelen',
    )
    const zonnepaneelAantallToSurfaceParam = parameters.find(
      (param) => param.name === 'Zonnepanelen',
    )
    const unitRateElec = parameters.find(
      (param) => param.name === 'Kostprijs elektriciteit',
    )
    const unitRateInjectie = parameters.find(
      (param) => param.name === 'Injectietarief',
    )
    let energyDifferencePanel = 0
    let energyRatePanel
    let verkoopElecPanel

    if (pages[23].aantal_zonnepanelen > 0) {
      // console.log("getPage26Responsese - calculation zonnepannel start")
      const verbruikZonnepaneelKwh =
        parameters.find((item) => item.name === 'Opbrengst per zonnepaneel') ||
        0
      const zelfconsumptieZonnepanelen =
        parameters.find(
          (item) => item.name === 'Zelfconsumptie zonnepanelen',
        ) || 0
      const area =
        Number(pages[23].aantal_zonnepanelen) *
        Number(zonnepaneelAantallToSurfaceParam.value)

      if (pages[25].answerNo == 0) {
        energyDifferencePanel =
          (area *
            verbruikZonnepaneelKwh.value *
            zelfconsumptieZonnepanelen.value) /
          100
        energyRatePanel =
          (area *
            verbruikZonnepaneelKwh.value *
            zelfconsumptieZonnepanelen.value *
            unitRateElec +
            area *
              verbruikZonnepaneelKwh.value *
              (100 - zelfconsumptieZonnepanelen.value) *
              unitRateInjectie) /
          100
        verkoopElecPanel =
          area *
          verbruikZonnepaneelKwh.value *
          ((100 - zelfconsumptieZonnepanelen.value) / 100)
      }
      if (pages[25].answerNo == 1) {
        energyDifferencePanel = area * verbruikZonnepaneelKwh.value
        energyRatePanel =
          area *
          Number(verbruikZonnepaneelKwh.value) *
          Number(unitRateElec.value)
        verkoopElecPanel =
          area *
          verbruikZonnepaneelKwh.value *
          (100 - zelfconsumptieZonnepanelen.value)
      }
    }

    // console.log("getOverviewValues energyDifferencePanel",energyDifferencePanel)
    // console.log("getOverviewValues energyRatePanel",energyRatePanel)
    // console.log("getOverviewValues verkoopElecPanel",verkoopElecPanel)

    localStorage.setItem('elektriciteitsverbruik',Math.round(energyUseElec))
    localStorage.setItem('aardgasverbruik',Math.round(energyUseGas))
    localStorage.setItem('zonnepanelen',Math.round(energyDifferencePanel))
    localStorage.setItem('energieproductie',Math.round(energieproductie?.value))
    
    return {
      elektriciteitsverbruik: Math.round(energyUseElec),
      aardgasverbruik: Math.round(energyUseGas),
      zonnepanelen: Math.round(energyDifferencePanel),
      energieproductie: Math.round(energieproductie?.value),
    }
  } catch(error) {
    console.error("Error in getPage26Responses:", error)
  } finally {
    dispatch(setLoading(false))
  }
}
